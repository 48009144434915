var render = function render(){var _vm=this,_c=_vm._self._c;return _c('notification-list',{attrs:{"notification-list":_vm.notificationList,"show":_vm.show},on:{"hasShownAll":function($event){_vm.setTitles([_vm.show, _vm.notificationList.length])
    _vm.$emit('shownAll')}},scopedSlots:_vm._u([{key:"default",fn:function({ notification }){return [(notification.type === 'Comment in')?[_vm._v("\n      A comment on this article appears in \""),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }},domProps:{"innerHTML":_vm._s(notification.title)}}):_c('span',{domProps:{"innerHTML":_vm._s(notification.title)}}),_vm._v("\"\n      "+_vm._s(notification.reference)+".\n    ")]:(notification.type === 'Comment on')?[_vm._v("\n      This is a comment on \""),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }},domProps:{"innerHTML":_vm._s(notification.title)}}):_c('span',{domProps:{"innerHTML":_vm._s(notification.title)}}),_vm._v("\"\n      "+_vm._s(notification.reference)+".\n    ")]:(notification.type === 'Erratum for')?[_vm._v("\n      This corrects \""),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }},domProps:{"innerHTML":_vm._s(notification.title)}}):_c('span',{domProps:{"innerHTML":_vm._s(notification.title)}}),_vm._v("\"\n      "+_vm._s(notification.reference)+".\n    ")]:(notification.type === 'Erratum in')?[_vm._v("\n      This article has been corrected. See\n      "),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }}},[_vm._v(_vm._s(notification.reference))]):_c('span',[_vm._v(_vm._s(notification.reference))]),_vm._v(".\n    ")]:(notification.type === 'Expression of concern in')?[_vm._v("\n      The editors have expressed concern for this article. See\n      "),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }}},[_vm._v(_vm._s(notification.reference))]):_c('span',[_vm._v(_vm._s(notification.reference))]),_vm._v(".\n    ")]:(notification.type === 'HIR')?[_vm._v("\n      Note: This Clinical Guideline may not be the most up-to-date version\n      available.\n    ")]:(notification.type === 'Original report in')?[_vm._v("\n      The original report appears in \""),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }},domProps:{"innerHTML":_vm._s(notification.title)}}):_c('span',{domProps:{"innerHTML":_vm._s(notification.title)}}),_vm._v("\"\n      "+_vm._s(notification.reference)+".\n    ")]:(notification.type === 'Partial retraction in')?[_vm._v("\n      This article has been partially retracted. See\n      "),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }}},[_vm._v(_vm._s(notification.reference))]):_c('span',[_vm._v(_vm._s(notification.reference))]),_vm._v(".\n    ")]:(notification.type === 'Partial retraction of')?[_vm._v("\n      This is a partial retraction of \""),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }},domProps:{"innerHTML":_vm._s(notification.title)}}):_c('span',{domProps:{"innerHTML":_vm._s(notification.title)}}),_vm._v("\"\n      "+_vm._s(notification.reference)+".\n    ")]:(notification.type === 'Preprint in')?[_vm._v("\n      This article is based on a previously available\n      "),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }}},[_vm._v("\n        preprint")]):_c('span',[_vm._v(" preprint")]),_vm._v(".\n    ")]:(notification.type === 'Preprint of')?[_vm._v("\n      This article is a preprint. A\n      "),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: {
            source: notification.source,
            id: notification.id,
            matomo_event: {
              category: 'Article',
              action: 'Preprint notification',
              name: 'Link to published article',
            },
          },
        }}},[_vm._v("\n        journal published article")]):_c('span',[_vm._v(" journal published article")]),_vm._v("\n      is available.\n    ")]:(notification.type === 'Preprint removal')?[(!notification.version)?[_vm._v("\n        This article is a preprint. This preprint has been removed by the\n        author(s).\n      ")]:[_vm._v("\n        This article is a preprint.\n        "),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
            name: 'article',
            params: {
              source: notification.version.source,
              id: notification.version.id,
            },
          }}},[_vm._v("Version "+_vm._s(notification.version.versionNumber)+" of this\n          preprint")]):_c('span',[_vm._v("Version "+_vm._s(notification.version.versionNumber)+" of this\n          preprint")]),_vm._v("\n        has been removed by the author(s).\n      ")],(notification.source)?[_vm._v("\n        A\n        "),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
            name: 'article',
            params: {
              source: notification.source,
              id: notification.id,
              matomo_event: {
                category: 'Article',
                action: 'Preprint notification',
                name: 'Link to published article',
              },
            },
          }}},[_vm._v("\n          journal published article")]):_c('span',[_vm._v(" journal published article")]),_vm._v("\n        is available.\n      ")]:_vm._e()]:(notification.type === 'Preprint withdrawal')?[(!notification.version)?[_vm._v("\n        This preprint has been withdrawn by the author(s).\n        "),(notification.url)?[_c('a',{attrs:{"href":notification.url}},[_vm._v("View this record on the preprint server")]),_vm._v("\n          for more information.\n        ")]:_vm._e()]:[_vm._v("\n        This article is a preprint.\n        "),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
            name: 'article',
            params: {
              source: notification.version.source,
              id: notification.version.id,
            },
          }}},[_vm._v("Version "+_vm._s(notification.version.versionNumber)+" of this\n          preprint")]):_c('span',[_vm._v("Version "+_vm._s(notification.version.versionNumber)+" of this\n          preprint")]),_vm._v("\n        has been withdrawn by the author(s).\n      ")],(notification.source)?[_vm._v("\n        A\n        "),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
            name: 'article',
            params: {
              source: notification.source,
              id: notification.id,
              matomo_event: {
                category: 'Article',
                action: 'Preprint notification',
                name: 'Link to published article',
              },
            },
          }}},[_vm._v("\n          journal published article")]):_c('span',[_vm._v(" journal published article")]),_vm._v("\n        is available.\n      ")]:_vm._e()]:(notification.type === 'Preprint not reviewed')?[_vm._v("\n      This article is a preprint. It may not have been peer reviewed.\n      "),_c('tooltip',[_c('i',{staticClass:"far fa-question-circle",attrs:{"slot":"trigger"},slot:"trigger"}),_vm._v("\n        A preprint is a complete scientific manuscript that an author uploads\n        on a public server for free viewing. Initially it is posted without\n        peer review, but may acquire feedback or reviews as a preprint, and\n        may eventually be published in a peer-reviewed journal. The posting of\n        preprints on public servers allows almost immediate dissemination and\n        scientific feedback early in the 'publication' process.\n      ")])]:(notification.type === 'Preprint under review')?[_vm._v("\n      This article is undergoing peer review. Please access the\n      "),_c('a',{attrs:{"href":'https://doi.org/' + notification.doi}},[_vm._v("article on the publisher's site")]),_vm._v("\n      to see the current peer review status.\n    ")]:(notification.type === 'Reprint in')?[_vm._v("\n      A reprint appears in\n      "),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }}},[_vm._v(_vm._s(notification.reference))]):_c('span',[_vm._v(_vm._s(notification.reference))]),_vm._v(".\n    ")]:(notification.type === 'Reprint of')?[_vm._v("\n      This article is a reprint of\n      "),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }}},[_vm._v(_vm._s(notification.reference))]):_c('span',[_vm._v(_vm._s(notification.reference))]),_vm._v(".\n    ")]:(notification.type === 'Republished from')?[_vm._v("\n      This has been republished from\n      "),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }}},[_vm._v(_vm._s(notification.reference))]):_c('span',[_vm._v(_vm._s(notification.reference))]),_vm._v(".\n    ")]:(notification.type === 'Republished in')?[_vm._v("\n      This has been republished in\n      "),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }}},[_vm._v(_vm._s(notification.reference))]):_c('span',[_vm._v(_vm._s(notification.reference))]),_vm._v(".\n    ")]:(notification.type === 'Retraction in')?[_vm._v("\n      This article has been retracted. See\n      "),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }}},[_vm._v(_vm._s(notification.reference))]):_c('span',[_vm._v(_vm._s(notification.reference))]),_vm._v(".\n    ")]:(notification.type === 'Retraction of')?[_vm._v("\n      This retracts \""),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }},domProps:{"innerHTML":_vm._s(notification.title)}}):_c('span',{domProps:{"innerHTML":_vm._s(notification.title)}}),_vm._v("\" in\n      "+_vm._s(notification.reference)+".\n    ")]:(notification.type === 'Summary for patients in')?[_vm._v("\n      A summary for patients appears in \""),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }},domProps:{"innerHTML":_vm._s(notification.title)}}):_c('span',{domProps:{"innerHTML":_vm._s(notification.title)}}),_vm._v("\"\n      "+_vm._s(notification.reference)+".\n    ")]:(notification.type === 'Update in')?[_vm._v("\n      This article has been updated in \""),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }},domProps:{"innerHTML":_vm._s(notification.title)}}):_c('span',{domProps:{"innerHTML":_vm._s(notification.title)}}),_vm._v("\"\n      "+_vm._s(notification.reference)+".\n    ")]:(notification.type === 'Update of')?[_vm._v("\n      This is an update of \""),(notification.source && notification.id)?_c('router-link',{attrs:{"to":{
          name: 'article',
          params: { source: notification.source, id: notification.id },
        }},domProps:{"innerHTML":_vm._s(notification.title)}}):_c('span',{domProps:{"innerHTML":_vm._s(notification.title)}}),_vm._v("\"\n      "+_vm._s(notification.reference)+".\n    ")]:(notification.type === 'Evaluations')?[(!notification.version)?[_vm._v("\n        This article is a preprint.\n        "),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.expand($event, 'reviews', true)}}},[_vm._v("Reviews & evaluations")]),_vm._v("\n        are available.\n      ")]:[_vm._v("\n        This article is a preprint. Reviews & evaluations are available for a\n        version(s) in the Version history below.\n      ")]]:_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }